import {
  getClickWhatsAppEndpoint,
  getFilterOptionsEndpoint,
  getSearchLocationsEndpoint,
  getSearchResultsEndpoint,
  getSearchCompoundEndpoint,
  getSearchDeveloperEndpoint,
  getSearchAreaEndpoint,
  getPhoneLeadInfoEndPoint,
  getAvailablePropertyTypesEndpoint,
  getCompoundEndpoint,
  getPropertyEndpoint,
  getCompoundsCachedEndpoint,
  getCompoundsEndpoint,
  getFooterLinksCachedEndpoint,
  submitContactFormEndpoint,
  getTopAreasEndPoint,
  getPopularCompoundsEndpoint,
  getFavoritesEndPoint,
  getWatchListEndPoint,
  getAreaEndpoint,
  getRecommendedPropertiesEndpoint,
  getDeveloperEndpoint,
  getDevelopersEndpoint,
  getTrackActivityEndpoint,
  getStatisticsEndpoint,
  getAreasCachedEndpoint,
  submitPropertyMonitorFormEndpoint,
  getFooterLinksEndpoint,
  getAreasEndpoint,
  getRecentSearchEndpoint,
  getCompoundsSearchResultsEndpoint,
  getLaunchesEndpoint,
  getLaunchesAreasEndpoint,
  getRecentlyLaunchedCompoundsEndpoint,
  getForecastingEndpoint,
  getRecommendedCompoundsEndpoint,
  getConfigVariablesEndpoint,
  getPropertyTypesEndpoint,
  getLaunchEndpoint,
  checkLaunchEndpoint,
  getCityscapeSearchEndpoint,
  getOffersEndpoint,
  getNewLandingPageEndpoint,
  optimizeImageEndpoint,
  getLaunchAreasEndpoint,
  resaleAdditionalDataEndpoint,
  getSahelCompoundsEndpoint,
  getSahelInfoEndpoint,
  getAreaFuzzyEndpoint,
  getPathwaysEndpoint,
  getCustomerLocationEndpoint,
  getFilterOptionsCachedEndpoint,
  getConfigVariablesCachedEndpoint,
  getOfferDevelopersEndpoint,
  getOfferAreasEndpoint,
  getNewOffersEndpoint,
  getOffersFilterOptionsEndpoint
} from './endpoints';
import axios from 'axios';

export const clickWhatsapp = async (
  body: any,
  language: string | undefined
) => {
  const END_POINT = getClickWhatsAppEndpoint(language);
  const data = await axios.post(END_POINT, body);
  return data;
};

export const fetchSearchResults = async (body: any, language?: string) => {
  const END_POINT = getSearchResultsEndpoint(language);
  const data = await axios.post(END_POINT, body);
  return data;
};

export const fetchCompoundSearchResults = async (
  body: any,
  language?: string
) => {
  const END_POINT = getCompoundsSearchResultsEndpoint(language);
  const data = await axios.post(END_POINT, body);
  return data;
};

export const submitContactForm = async (body: any, language?: string) => {
  const END_POINT = submitContactFormEndpoint(language);
  const data = await axios.post(END_POINT, body);
  return data;
};

export const getFilterOptions = async (language?: string) => {
  const END_POINT = getFilterOptionsEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getFilterOptionsCached = async (language?: string) => {
  const END_POINT = getFilterOptionsCachedEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getSearchLocations = async (
  term: string,
  language?: string,
  northcoast?: boolean,
  exclude?: string[]
) => {
  const END_POINT = getSearchLocationsEndpoint(
    term,
    language,
    northcoast,
    exclude
  );
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getAreaFuzzy = async (term: string, language?: string) => {
  const END_POINT = getAreaFuzzyEndpoint(term, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getSearchCompound = async (id: number, language?: string) => {
  const END_POINT = getSearchCompoundEndpoint(id, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getSearchDeveloper = async (id: number, language?: string) => {
  const END_POINT = getSearchDeveloperEndpoint(id, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getSearchArea = async (id: number, language?: string) => {
  const END_POINT = getSearchAreaEndpoint(id, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const phoneLeadsInfo = async (body: any, language?: string) => {
  const END_POINT = getPhoneLeadInfoEndPoint(language);
  const res = await axios.post(END_POINT, body);
  return res.data;
};

export const getTopAreas = async (language?: string) => {
  const END_POINT = getTopAreasEndPoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getCompound = async (
  slug: string,
  language?: string,
  detail?: string
) => {
  const END_POINT = getCompoundEndpoint(slug, language, detail);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getCompoundsCached = async (
  language?: string,
  moveNowOnly?: boolean
) => {
  const END_POINT = getCompoundsCachedEndpoint(language, moveNowOnly);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getAreasCached = async (
  language?: string,
  moveNowOnly?: boolean
) => {
  const END_POINT = getAreasCachedEndpoint(language, moveNowOnly);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getCompounds = async (
  language?: string,
  moveNowOnly?: boolean,
  body?: any
) => {
  const END_POINT = getCompoundsEndpoint(language, moveNowOnly, body);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getFooterLinks = async (language?: string) => {
  const END_POINT = getFooterLinksEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getFooterLinksCached = async (language?: string) => {
  const END_POINT = getFooterLinksCachedEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getAvailablePropertyTypes = async (
  compoundId: number,
  language?: string
) => {
  const END_POINT = getAvailablePropertyTypesEndpoint(compoundId, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getProperty = async (
  slug: string,
  language?: string,
  detail?: string
) => {
  const END_POINT = getPropertyEndpoint(slug, language, detail);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getPopularCompounds = async (language?: string) => {
  const END_POINT = getPopularCompoundsEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getRecentSearch = async (language?: string) => {
  const END_POINT = await getRecentSearchEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getFavorites = async (clientID: string, language?: string) => {
  const END_POINT = getFavoritesEndPoint(clientID, language);
  const res = await axios.get(END_POINT);
  return res.data;
};
export const getArea = async (slug: string, language?: string) => {
  const END_POINT = getAreaEndpoint(slug, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const updateWatchList = async (body: any, action?: string) => {
  const END_POINT = getWatchListEndPoint(action);
  const res = await axios.post(END_POINT, body);
  return res.data;
};
export const getDeveloper = async (slug: string, language?: string) => {
  const END_POINT = getDeveloperEndpoint(slug, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getDevelopers = async (
  with_summary?: boolean,
  language?: string
) => {
  const END_POINT = getDevelopersEndpoint(with_summary, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getAreas = async (with_summary?: boolean, language?: string) => {
  const END_POINT = getAreasEndpoint(with_summary, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getRecommendedProperties = async (
  language?: string,
  propertyID?: number | number[]
) => {
  const END_POINT = await getRecommendedPropertiesEndpoint(
    language,
    propertyID
  );
  const res = await axios.get(END_POINT);
  return res.data;
};

export const trackActivity = async (body: any, language?: string) => {
  const END_POINT = getTrackActivityEndpoint(language);
  const res = await axios.post(END_POINT, body);
  return res.data;
};
export const getStatistics = async (language?: string) => {
  const END_POINT = getStatisticsEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getNewLandingPage = async (id: string) => {
  const END_POINT = getNewLandingPageEndpoint(id);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const submitPropertyMonitorForm = async (
  body: any,
  language?: string
) => {
  const END_POINT = submitPropertyMonitorFormEndpoint(language);
  const res = await axios.post(END_POINT, body);
  return res.data;
};

export const getForecasting = async (id: number) => {
  const END_POINT = getForecastingEndpoint(id);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getRecommendedCompounds = async (
  language?: string,
  id?: number
) => {
  const END_POINT = getRecommendedCompoundsEndpoint(language, id);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getLaunchesSearch = async (
  language?: string,
  body?: any,
  summary?: string
) => {
  const END_POINT = getLaunchesEndpoint(language, summary);
  const res = await axios.post(END_POINT, body);
  return res.data;
};

export const getLaunchesAreas = async (language?: string) => {
  const END_POINT = getLaunchesAreasEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getRecentlyLaunchedCompounds = async (
  language?: string,
  body?: any
) => {
  const END_POINT = getRecentlyLaunchedCompoundsEndpoint(language);
  const res = await axios.post(END_POINT, body);
  return res.data;
};

export const getConfigVariables = async () => {
  const END_POINT = getConfigVariablesEndpoint();
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getPropertyTypes = async (language?: string) => {
  const END_POINT = getPropertyTypesEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getLaunch = async (slug: string, language?: string) => {
  const END_POINT = getLaunchEndpoint(slug, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getLaunchAreas = async (language?: string) => {
  const END_POINT = getLaunchAreasEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const checkLaunch = async (slug: string) => {
  const END_POINT = checkLaunchEndpoint(slug);
  const res: any = await axios.get(END_POINT);
  return res.data;
};

export const getOffers = async (params: {}, language?: string) => {
  const END_POINT = getOffersEndpoint(language);
  const res = await axios.get(END_POINT, { params });
  return res.data;
};

export const getNewOffers = async (body: any, language?: string) => {
  const END_POINT = getNewOffersEndpoint(language);
  const res = await axios.post(END_POINT, body);
  return res.data;
};

export const getCityscapeSearch = async (term: string, language?: string) => {
  const END_POINT = getCityscapeSearchEndpoint(term, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const optimizeImage = async (image: string) => {
  const END_POINT = optimizeImageEndpoint(image);
  const res = await axios.get(END_POINT);
  return res;
};

export const addResaleData = async (body: any) => {
  const END_POINT = resaleAdditionalDataEndpoint();
  const res = await axios.post(END_POINT, body);
  return res;
};
export const getSahelCompounds = async (
  body: any,
  language?: string,
  area_id?: number
) => {
  const END_POINT = getSahelCompoundsEndpoint(language, area_id);
  const res = await axios.post(END_POINT, body);

  return res.data;
};

export const getSahelInfo = async (language?: string) => {
  const END_POINT = getSahelInfoEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getPathways = async (type: string, language?: string) => {
  const END_POINT = getPathwaysEndpoint(type, language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getCustomerLocation = async () => {
  const END_POINT = getCustomerLocationEndpoint();
  try {
    const res = await axios.get(END_POINT);
    return res.data;
  } catch (err) {
    return false;
  }
};
export const getConfigVariablesCached = async () => {
  const END_POINT = getConfigVariablesCachedEndpoint();
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getOffersFilterOptions = async (language?: string) => {
  const END_POINT = getOffersFilterOptionsEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getOffersDevelopers = async (language?: string) => {
  const END_POINT = getOfferDevelopersEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};

export const getOffersAreas = async (language?: string) => {
  const END_POINT = getOfferAreasEndpoint(language);
  const res = await axios.get(END_POINT);
  return res.data;
};
