import { SetStateAction, useContext, useEffect, useState } from 'react';
import { SearchFiltersTypes } from '../../../reducers/search-filters/reducer';
import { useRouter } from 'next/router';

import {
  getSearchArea,
  getSearchCompound,
  getSearchDeveloper,
  getSearchLocations
} from '../../../new-lib/apis';
import { SearchFiltersActionTypes } from '../../../reducers/search-filters/actions';
import { updateQuery } from '../../../helpers/common';
import { logEvent } from '../../../new-lib/analytics';
import {
  CategoryTypes,
  SearchInputResult
} from '../components/search-bar/SearchLocationResults';
import { SearchSelection } from '../components/search-bar/SearchSelections';
import {
  SearchContext,
  SearchContextInterface
} from '../context/SearchContext';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { MapMode } from '../../sahel-mapbox/types';

interface SearchProps {
  searchInputLocation: string;
  setDropdownOpen?: React.Dispatch<SetStateAction<boolean>>;
}

export const useSearch = ({
  setDropdownOpen,
  searchInputLocation
}: SearchProps) => {
  const {
    searchFiltersState,
    searchFiltersDispatch,
    showUniversalSearch,
    setShowUniversalSearch,
    appliedFiltersCount
  } = useContext(SearchContext) as SearchContextInterface;
  const router = useRouter();
  const { locale, pathname, asPath } = router;
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedDevelopers, setSelectedDevelopers] = useState<
    SearchInputResult[]
  >([]);
  const [selectedAreas, setSelectedAreas] = useState<SearchInputResult[]>([]);
  const [selectedCompounds, setSelectedCompounds] = useState<
    SearchInputResult[]
  >([]);
  const [selectedSearches, setSelectedSearches] = useState<SearchSelection[]>(
    []
  );
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const mapMode = useSelector((state: any) => state.map.mode);

  const {
    data: searchResults,
    isFetching,
    isError
  } = useQuery(
    [
      'searchLocations',
      searchTerm,
      locale,
      asPath.includes('/sahel-map') && mapMode === MapMode.CalculateDistance
    ],
    () =>
      getSearchLocations(
        searchTerm,
        locale,
        searchInputLocation === 'SahelMap',
        asPath.includes('/sahel-map') && mapMode === MapMode.CalculateDistance
          ? ['developers']
          : []
      ),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );

  const compoundRoute = async (compound: SearchInputResult) => {
    router.push(`/compound/${compound.slug}`);
    logEvent.search(
      'search_select',
      searchInputLocation,
      'search',
      searchFiltersState,
      {
        compound_id: compound.id
      }
    );
  };

  const launchRoute = async (launch: SearchInputResult) => {
    router.push(`/${launch.prefix}/${launch.slug}`);

    logEvent.search(
      'search_select',
      searchInputLocation,
      'search',
      searchFiltersState,
      {
        lnch_id: launch.id
      }
    );
  };

  const updateSearchFilters = (
    locationCategory: SearchFiltersTypes.AREAS | SearchFiltersTypes.DEVELOPERS,
    ids: number[]
  ) => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: locationCategory,
        value: ids
      }
    });
    if (isMobile || (!isMobile && pathname != '/')) {
      updateQuery(router, [
        {
          name: locationCategory,
          value: ids.toString()
        }
      ]);
    }
  };

  const addSearchLocation = (selection: SearchInputResult) => {
    if (
      selection.category == 'developer' &&
      !searchFiltersState.searchFilters.developers.includes(selection.id)
    ) {
      let developersIds = [
        ...searchFiltersState.searchFilters.developers,
        selection.id
      ];
      updateSearchFilters(SearchFiltersTypes.DEVELOPERS, developersIds);
    } else if (
      selection.category == 'area' &&
      !searchFiltersState.searchFilters.areas.includes(selection.id)
    ) {
      let areasIds = [...searchFiltersState.searchFilters.areas, selection.id];
      updateSearchFilters(SearchFiltersTypes.AREAS, areasIds);
    }

    if (searchInputLocation == 'UniversalSearch' && setDropdownOpen) {
      setDropdownOpen(false);
    }
  };

  const handleSelect = (selection: SearchInputResult) => {
    setSearchTerm('');

    if (selection.category == CategoryTypes.COMPOUND) {
      compoundRoute(selection).catch(err => err);
    } else if (selection.category == CategoryTypes.LAUNCH) {
      launchRoute(selection).catch(err => err);
    } else if (
      !pathname.includes('/search') &&
      !(pathname == '/' && !isMobile)
    ) {
      router.push({
        pathname: '/search',
        query: `${selection.category}s=${selection.id}`
      });
      logEvent.search(
        'search_select',
        searchInputLocation,
        'search',
        searchFiltersState,
        {
          [selection.category == 'area' ? 'area_id' : 'dev_id']: selection.id
        }
      );
    } else {
      addSearchLocation(selection);
      logEvent.search(
        'search_select',
        searchInputLocation,
        'search',
        searchFiltersState,
        {
          [selection.category == 'area' ? 'area_id' : 'dev_id']: selection.id
        }
      );
    }
  };

  const removeSelected = (
    newArr: number[],
    locationCategory: SearchFiltersTypes.AREAS | SearchFiltersTypes.DEVELOPERS,
    isClearAll?: boolean
  ) => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: locationCategory,
        value: newArr
      }
    });
    if (newArr.length > 0 && pathname != '/') {
      updateQuery(router, [
        {
          name: locationCategory,
          value: newArr.toString()
        }
      ]);
    } else if (!isClearAll) {
      updateQuery(router, undefined, [locationCategory]);
    }
  };

  const handleRemove = (selection: SearchSelection) => {
    if (selection.category == 'developer') {
      let newArr = selectedDevelopers
        .filter(item => item.id !== selection.id)
        .map(item => item.id);

      removeSelected(newArr, SearchFiltersTypes.DEVELOPERS);
    } else {
      let newArr = selectedAreas
        .filter(item => item.id !== selection.id)
        .map(item => item.id);
      removeSelected(newArr, SearchFiltersTypes.AREAS);
    }
  };
  const clearSearch = () => {
    removeSelected([], SearchFiltersTypes.AREAS, true);
    removeSelected([], SearchFiltersTypes.DEVELOPERS, true);
    updateQuery(router, undefined, [
      SearchFiltersTypes.AREAS,
      SearchFiltersTypes.DEVELOPERS
    ]);
  };

  useEffect(() => {
    if (searchTerm !== '')
      logEvent.search(
        'search_query',
        searchInputLocation,
        'search',
        searchFiltersState,
        {
          search_query: searchTerm
        }
      );
  }, [searchTerm]);

  const getDevelopers = async () => {
    let developers = searchFiltersState.searchFilters.developers;
    const results = developers.map(id => {
      return getSearchDeveloper(id, locale);
    });
    let devArr: SearchInputResult[] = [];
    Promise.all(results)
      .then((res: any) => {
        res.forEach((developer: any) => {
          if (developer) {
            devArr.push({
              name: developer.name,
              id: developer.id,
              category: CategoryTypes.DEVELOPER
            });
          }
        });
        setSelectedDevelopers(devArr);
      })
      .catch(err => err);
  };

  const getAreas = async () => {
    let areas = searchFiltersState.searchFilters.areas;
    const results = areas.map(id => {
      return getSearchArea(id, locale);
    });
    let areasArray: SearchInputResult[] = [];
    Promise.all(results)
      .then((res: any) => {
        res.forEach((area: any) => {
          if (area) {
            areasArray.push({
              name: area.name,
              id: area.id,
              category: CategoryTypes.AREA
            });
          }
        });
        setSelectedAreas(areasArray);
      })
      .catch(err => err);
  };

  const getCompounds = async () => {
    let compounds = searchFiltersState.searchFilters.compounds;
    const results = compounds.map(id => {
      return getSearchCompound(id, locale);
    });
    let compoundsArray: SearchInputResult[] = [];
    Promise.all(results)
      .then((res: any) => {
        res.forEach((compound: any) => {
          if (compound) {
            compoundsArray.push({
              name: compound.name,
              id: compound.id,
              category: CategoryTypes.COMPOUND
            });
          }
        });
        setSelectedCompounds(compoundsArray);
      })
      .catch(err => err);
  };

  useEffect(() => {
    getAreas().catch(err => err);
  }, [searchFiltersState.searchFilters.areas]);
  useEffect(() => {
    getDevelopers().catch(err => err);
  }, [searchFiltersState.searchFilters.developers]);
  useEffect(() => {
    getCompounds().catch(err => err);
  }, [searchFiltersState.searchFilters.compounds]);

  useEffect(() => {
    setSelectedSearches([
      ...selectedAreas,
      ...selectedDevelopers,
      ...selectedCompounds
    ]);
  }, [selectedAreas, selectedDevelopers, selectedCompounds]);

  useEffect(() => {
    if (pathname != '/') setShowUniversalSearch(false);
  }, [pathname]);

  useEffect(() => {
    if (searchInputLocation == 'UniversalSearch' && setDropdownOpen) {
      setDropdownOpen(false);
    }
  }, [asPath]);

  useEffect(() => {
    if (
      pathname == '/' &&
      searchInputLocation == 'UniversalSearch' &&
      setDropdownOpen
    ) {
      showUniversalSearch ? setDropdownOpen(true) : setDropdownOpen(false);
    }
  }, [showUniversalSearch]);

  useEffect(() => {
    if (
      searchFiltersState.searchFilters.areas.length == 0 &&
      searchFiltersState.searchFilters.developers.length == 0 &&
      searchFiltersState.searchFilters.compounds.length == 0
    ) {
      setSelectedSearches([]);
    }
  }, [
    searchFiltersState,
    selectedAreas,
    selectedDevelopers,
    selectedCompounds
  ]);

  return {
    searchTerm,
    isLoading: isFetching,
    isError,
    setSearchTerm,
    searchResults,
    selectedSearches,
    clearSearch,
    handleSelect,
    handleRemove,
    appliedFilters: appliedFiltersCount
  };
};
