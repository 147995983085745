import styled from 'styled-components';
import FilterIcon from '../../../../new-components/svg-icons/FilterIcon';
import { Dispatch, SetStateAction } from 'react';
import { logEvent } from '../../../../new-lib/analytics';

interface FilterBtnProps {
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  appliedFilters: number;
}

const SearchFilterBtn = ({
  setShowFilters,
  appliedFilters
}: FilterBtnProps) => {
  return (
    <Container
      onClick={() => {
        setShowFilters(true);
        logEvent.search(
          'filter_click',
          'MobileFiltersBar',
          'filter',
          {} as any
        );
      }}
      data-cy="filter-btn"
    >
      <FilterIcon />
      {appliedFilters > 0 && (
        <span className="filters-indicator">{appliedFilters}</span>
      )}
    </Container>
  );
};

const Container = styled.div`
  min-width: 45px;
  border: 1px solid ${props => props.theme.light.borderColors.primary};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  &:active {
    transform: scale(0.95);
    transition: transform 0.3s ease;
  }

  cursor: pointer;
  span {
    position: absolute;
    top: 2px;
    inset-inline-end: 4px;
    font-size: 8px;
    color: ${props => props.theme.light.colors.white};
    background-color: ${props => props.theme.light.colors.primaryOrange};
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    background-color: #f2f2f2;
    border: none;
  }
`;
export default SearchFilterBtn;
