import { useContext, useEffect, useState } from 'react';
import { SearchFiltersTypes } from '../../../reducers/search-filters/reducer';
import { useRouter } from 'next/router';

import {
  getAreaFuzzy,
  getSearchCompound,
  getSearchArea,
  getSearchLocations
} from '../../../new-lib/apis';
import { SearchFiltersActionTypes } from '../../../reducers/search-filters/actions';
import {
  SearchContext,
  SearchContextInterface
} from '../context/SearchContext';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import {
  resetFilters,
  setAreas,
  setCompounds,
  setSelectedType
} from '../../../redux/slices/nawyNowSlice';
import { logEvent } from '../../../new-lib/analytics';
import { getPageLocation, updateQuery } from '../../../helpers/common';

export interface SearchSelection {
  name: string;
  id: number;
  category: string;
}

export interface FilterTypeObject {
  selectedType: 'areas' | 'compounds';
  areas: SearchSelection[];
  compounds: SearchSelection[];
}

export const useLocationFilter = () => {
  const { searchFiltersState, searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const filtersContainer = useSelector((state: any) => state.nawyNow);
  const dispatch = useDispatch();

  const router = useRouter();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { locale, asPath } = router;
  const [searchTerm, setSearchTerm] = useState('');

  const { data: compoundResults } = useQuery(
    ['compoundSearchLocations', searchTerm, locale],
    () =>
      getSearchLocations(searchTerm, locale, undefined, [
        'developers',
        'launches'
      ]),
    {
      keepPreviousData: true,
      retry: false,
      enabled: filtersContainer.selectedType == 'compounds'
    }
  );
  const { data: areaResults } = useQuery(
    ['areaSearchLocations', searchTerm, locale],
    () => getAreaFuzzy(searchTerm, locale),
    {
      keepPreviousData: true,
      retry: false,
      enabled: filtersContainer.selectedType == 'areas'
    }
  );

  const changeSelectedTab = (selectedTab: 'areas' | 'compounds') => {
    if (selectedTab == filtersContainer.selectedType) return;
    dispatch(setSelectedType(selectedTab));
  };

  const updateSearchFilters = (
    newArr: SearchSelection[],
    searchCategory: SearchFiltersTypes.AREAS | SearchFiltersTypes.COMPOUNDS
  ) => {
    let filtersIds = newArr.map((result: SearchSelection) => result.id);
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: searchCategory,
        value: filtersIds
      }
    });
    if (filtersIds.length == 0) {
      updateQuery(router, undefined, [searchCategory]);
    } else {
      updateQuery(
        router,
        [
          {
            name: searchCategory,
            value: filtersIds.toString()
          }
        ],
        []
      );
    }
  };
  const handleSelect = (selection: SearchSelection) => {
    if (filtersContainer.selectedType == 'areas') {
      const areasArr = [...filtersContainer['areas'], selection];
      if (filtersContainer['compounds'].length > 0) {
        dispatch(setCompounds([]));
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
          payload: {
            name: SearchFiltersTypes.COMPOUNDS,
            value: []
          }
        });
        updateQuery(router, [], [SearchFiltersTypes.COMPOUNDS]);
      }
      dispatch(setAreas(areasArr));
      updateSearchFilters(areasArr, SearchFiltersTypes.AREAS);

      logEvent.click(
        'area_location_filter',
        getPageLocation(asPath),
        `nawy_now`,
        {
          area_id: selection.id,
          area_name: selection.name
        }
      );
    } else if (filtersContainer.selectedType == 'compounds') {
      const compoundsArr = [...filtersContainer['compounds'], selection];
      if (filtersContainer['areas'].length > 0) {
        dispatch(setAreas([]));
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
          payload: {
            name: SearchFiltersTypes.AREAS,
            value: []
          }
        });
        updateQuery(router, [], [SearchFiltersTypes.AREAS]);
      }
      dispatch(setCompounds(compoundsArr));
      updateSearchFilters(compoundsArr, SearchFiltersTypes.COMPOUNDS);
      logEvent.click(
        'compound_location_filter',
        getPageLocation(asPath),
        `nawy_now`,
        {
          cmpnd_id: selection.id,
          cmpnd_name: selection.name
        }
      );
    }
  };

  const getCompounds = () => {
    let compoundsIds = searchFiltersState.searchFilters.compounds;
    const results = compoundsIds.map(id => {
      return getSearchCompound(id, locale);
    });
    let compounds: SearchSelection[] = [];
    Promise.all(results)
      .then((res: any) => {
        res.map((compound: any) => {
          if (compound) {
            compounds.push({
              name: compound.name,
              id: compound.id,
              category: 'compound'
            });
          }
        });
        dispatch(setCompounds(compounds));
      })
      .catch(err => err);
  };

  const getAreas = async () => {
    let areasIds = searchFiltersState.searchFilters.areas;
    let areas: SearchSelection[] = [];
    const results = areasIds.map(id => {
      return getSearchArea(id, locale);
    });
    Promise.all(results)
      .then((res: any) => {
        res.map((area: any) => {
          if (area) {
            areas.push({
              name: area.name,
              id: area.id,
              category: 'area'
            });
          }
        });
        dispatch(setAreas(areas));
      })
      .catch(err => err);
  };

  const clearAll = () => {
    updateSearchFilters([], SearchFiltersTypes.AREAS);
    updateSearchFilters([], SearchFiltersTypes.COMPOUNDS);
  };
  const handleRemove = (selection: SearchSelection) => {
    const newArr = filtersContainer[filtersContainer.selectedType].filter(
      (result: SearchSelection) => result.id != selection.id
    );
    if (filtersContainer.selectedType == 'areas') {
      dispatch(setAreas(newArr));
      updateSearchFilters(newArr, SearchFiltersTypes.AREAS);
    } else if (filtersContainer.selectedType == 'compounds') {
      dispatch(setCompounds(newArr));
      updateSearchFilters(newArr, SearchFiltersTypes.COMPOUNDS);
    }
  };

  useEffect(() => {
    if (searchFiltersState.searchFilters.areas.length > 0) {
      getAreas();
    }
    if (searchFiltersState.searchFilters.compounds.length > 0) {
      getCompounds();
    }
  }, []);

  useEffect(() => {
    if (
      searchFiltersState.searchFilters.areas.length == 0 &&
      searchFiltersState.searchFilters.compounds.length == 0
    ) {
      dispatch(resetFilters());
    }
  }, [searchFiltersState.searchFilters]);

  return {
    searchTerm,
    setSearchTerm,
    searchResults:
      filtersContainer.selectedType == 'areas' ? areaResults : compoundResults,
    filtersContainer,
    clearAll,
    handleSelect,
    handleRemove,
    setDropDownOpen,
    dropDownOpen,
    changeSelectedTab
  };
};
