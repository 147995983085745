import Head from 'next/head';
import { useRouter } from 'next/router';
import { containsAnyWord, getImagePath } from '../../helpers/common';
import HeaderContainer from './header/HeaderContainer';
import ContactFormContainer from '../../features/contact/components/organic-form/ContactFormContainer';
import FooterContainer from '../footer/FooterContainer';
import FormHeader from '../../features/contact/components/organic-form/FormHeader';
import CompareToolbar from '../../features/compare/components/CompareToolbar';
import CompareWidget from '../../features/compare/components/mobile/CompareWidget';
import DownloadAppPopup from '../../features/compare/components/mobile/DownloadAppPopup';
import CookieConsent from '../ui/CookieConsent';
import 'react-phone-number-input/style.css';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

const Toaster = dynamic(() => import('./components/Toaster'), { ssr: false });

interface LayoutProps {
  layout: {
    title: string;
    url?: string;
    image?: string;
    metaDescription: string;
    alternatePaths?: {
      en: string;
      ar: string;
    };
    footerLinks: any;
    searchResults?: any;
  };
  children: React.ReactNode;
  filterOptions?: any;
  query?: any;
  path?: string;
  pageTitle?: any;
  sbjs?: any;
  loadedSearchResultsSSR?: any;
}

const Layout: React.FC<LayoutProps> = ({
  layout,
  children,
  loadedSearchResultsSSR
}) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const webview = useSelector((state: any) => state.app.webview);
  const pathsWithoutAnyLayout = [
    'top-compounds',
    'prime-compounds',
    'real-estate-360',
    'nawy-partners',
    'freelancers',
    'app-announcements',
    'landing',
    '404',
    '/success',
    ...(webview === 'partners' ? ['/new-launches', '/compound'] : []),
    ...(webview === 'app' ? ['/terms', '/privacy-policy'] : [])
  ];

  const desktopPathsWithoutContactForm = [
    'search',
    'buy',
    'compound',
    'contact',
    'nawy-now',
    'sell-my-property',
    'new-launches',
    'cityscape',
    '404',
    'compare',
    'sahel-map'
  ];

  const mobilePathsWithoutContactForm = [
    'search',
    'buy',
    'contact',
    'nawy-now',
    'sell-my-property',
    'new-launches',
    '404',
    'cityscape',
    'compound',
    'compare',
    'sahel-map'
  ];

  const pathsWithoutCompareWidget = [
    'compare',
    'contact',
    'sell-my-property',
    'new-launches',
    '404',
    'cityscape',
    'about-us',
    'app-announcements',
    'top-compounds',
    'landing',
    'how-it-works',
    'privacy-policy',
    'real-estate-360',
    'terms'
  ];

  const { title, url, image, metaDescription, alternatePaths, footerLinks } = {
    ...Layout.defaultProps?.layout,
    ...layout
  };

  const getTitle = (title: string) => {
    if (
      locale === 'ar' &&
      title === 'Nawy Estate | Premium Real Estate in Egypt'
    ) {
      return 'Nawy Estate | العقارات المتميزة في مصر';
    }

    return title;
  };

  const getDescription = (description: string) => {
    if (
      locale === 'ar' &&
      description ===
        'The ultimate real estate broker in Egypt. Browse areas, compounds, and properties by price, location, and amenities to find the perfect place.'
    ) {
      return 'أفضل وسيط عقاري في مصر. تصفح المناطق والمجمعات السكنية والعقارات حسب السعر والموقع والمرافق للعثور على المكان المثالي';
    }

    return description;
  };

  const { pathname, asPath, locale } = useRouter();

  const localizedTitle = getTitle(title);
  const localizedDescription = getDescription(metaDescription);

  const currentPath = asPath.split('?')[0];

  const paths = {
    en:
      alternatePaths?.en ||
      `${process.env.NEXT_PUBLIC_HOST}${currentPath.replace(/\/$/, '')}`,
    ar:
      alternatePaths?.ar ||
      `${process.env.NEXT_PUBLIC_HOST}/ar${currentPath.replace(/\/$/, '')}`
  };

  const showAlternateLinks = () => {
    if (pathname.includes('[property_type]')) {
      return false;
    }
    return true;
  };

  const showUniversalSearch = () => {
    if (
      url &&
      url !== process.env.NEXT_PUBLIC_HOST &&
      url !== `${process.env.NEXT_PUBLIC_HOST}/404` &&
      url !== `${process.env.NEXT_PUBLIC_HOST}/cityscape` &&
      url === `${process.env.NEXT_PUBLIC_HOST}/sahel-map`
    ) {
      return true;
    }
    return false;
  };

  const getDesktopLayoutContent = () => {
    return (
      !containsAnyWord(pathname, desktopPathsWithoutContactForm) && (
        <div className="container-fluid" style={{ marginTop: 'auto' }}>
          <ContactFormContainer
            renderedInputs={{
              name: true,
              location: true,
              message: true,
              mobileNumber: true
            }}
            withLabels
            layout={{ header: <FormHeader hasIcon={true} /> }}
          />
        </div>
      )
    );
  };
  const getMobileLayoutContent = () => {
    return (
      !containsAnyWord(pathname, mobilePathsWithoutContactForm) && (
        <div className="container-fluid" style={{ marginTop: 'auto' }}>
          <ContactFormContainer
            renderedInputs={{
              name: true,
              location: true,
              message: true,
              mobileNumber: true
            }}
            withLabels
            layout={{ header: <FormHeader hasIcon={true} /> }}
          />
        </div>
      )
    );
  };

  return (
    <div dir={locale == 'ar' ? 'rtl' : 'ltr'}>
      <Head>
        <title>{localizedTitle}</title>
        <meta name="title" content={localizedTitle} />

        <meta
          name="description"
          content={localizedDescription}
          key="description"
        />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Old cooingestate.com */}
        <meta
          name="google-site-verification"
          content="oAi-bIMRabSTIf1JqcetCXVR6M0xwV_zaXpWV0mpauE"
        />
        {/* New nawy.com */}
        <meta
          name="google-site-verification"
          content="vFMcUb9kmXPw2BzB42fkSUPumjND8ErdU7DP-UBHKNU"
        />
        <meta property="fb:app_id" content="1964171580505460" />
        <meta property="cf_country" content="{{ $CfCountry }}" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge"></meta>
        <meta name="base_url" content={'/'}></meta>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/favicon.ico"
        ></link>
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
        <link rel="apple-touch-icon" href="/favicon.ico"></link>

        {/* Open Graph - Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={localizedTitle} key="title" />
        <meta property="og:description" content={localizedDescription} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={getImagePath(image)} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={localizedTitle} />
        <meta property="twitter:description" content={localizedDescription} />
        <meta property="twitter:image" content={getImagePath(image)} />

        <link rel="canonical" href={locale === 'en' ? paths.en : paths.ar} />
        {showAlternateLinks() && (
          <>
            <link rel="alternate" hrefLang="en" href={paths.en} />
            <link rel="alternate" hrefLang="ar" href={paths.ar} />
          </>
        )}
        {locale === 'en' && (
          <>
            <link
              rel="preload"
              href="/assets/fonts/Poppins/Poppins-Bold.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
            <link
              rel="preload"
              href="/assets/fonts/Poppins/Poppins-Medium.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
            <link
              rel="preload"
              href="/assets/fonts/Poppins/Poppins-Regular.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
            <link
              rel="preload"
              href="/assets/fonts/Poppins/Poppins-Light.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
          </>
        )}

        {locale === 'ar' && (
          <>
            <link
              rel="preload"
              href="/assets/fonts/Almarai/Almarai-Bold.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
            <link
              rel="preload"
              href="/assets/fonts/Almarai/Almarai-Medium.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
            <link
              rel="preload"
              href="/assets/fonts/Almarai/Almarai-Regular.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
            <link
              rel="preload"
              href="/assets/fonts/Almarai/Almarai-Light.woff2"
              as="font"
              crossOrigin="anonymous"
              type="font/woff2"
            />
          </>
        )}

        <link rel="preconnect" href="https://webapi.cooingestate.com" />

        <meta name="robots" content="index,follow" />
      </Head>

      {webview !== 'partners' && webview !== 'app' && (
        <div className="toolbar">
          <CookieConsent />
          {!containsAnyWord(pathname, pathsWithoutCompareWidget) &&
            (isMobile ? <CompareWidget /> : <CompareToolbar />)}
        </div>
      )}

      <DownloadAppPopup />

      <Toaster />

      {!containsAnyWord(pathname, pathsWithoutAnyLayout) ? (
        <>
          <HeaderContainer
            paths={paths}
            loadedSearchResultsSSR={loadedSearchResultsSSR}
            forceMobile={isMobile}
          />

          <div
            className={
              isMobile && showUniversalSearch()
                ? 'app-wrapper search-bar-included'
                : 'app-wrapper'
            }
          >
            {children}
          </div>
          {isMobile ? getMobileLayoutContent() : getDesktopLayoutContent()}
          {!pathname.includes('/sahel-map') && (
            <FooterContainer footerLinks={footerLinks} forceMobile={isMobile} />
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

Layout.defaultProps = {
  layout: {
    title: 'Nawy Estate | Premium Real Estate in Egypt',
    url: process.env.NEXT_PUBLIC_HOST,
    metaDescription:
      'The ultimate real estate broker in Egypt. Browse areas, compounds, and properties by price, location, and amenities to find the perfect place.',
    image: 'https://nawy.com/assets/images/common/favicon.webp',
    footerLinks: []
  }
};

export default Layout;
