import { Dispatch } from 'react';
import { NextRouter } from 'next/router';
import { InputOption } from '../new-lib/types/search-filters';
import {
  SearchFiltersActionTypes,
  SearchFiltersActions
} from '../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../reducers/search-filters/reducer';
import { convertStringArrayToNumberArray, updateQuery } from './common';
import { isValidDate } from './search-options/common';

export const initializeSearchFiltersFromQuery = (
  query: object,
  dispatch: React.Dispatch<SearchFiltersActions>
) => {
  if (query && Object.keys(query).length == 0) return;
  for (const [key, value] of Object.entries(query)) {
    switch (key) {
      case SearchFiltersTypes.CATEGORY:
        if (value == 'property' || value == 'compound')
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: value
            }
          });
        break;
      case SearchFiltersTypes.MIN_PRICE:
      case SearchFiltersTypes.MAX_PRICE:
      case SearchFiltersTypes.MIN_UNIT_AREA:
      case SearchFiltersTypes.MAX_UNIT_AREA:
      case SearchFiltersTypes.DOWN_PAYMENT:
      case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
        if (!isNaN(value))
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: parseInt(value)
            }
          });
        break;
      case SearchFiltersTypes.PAGE_NUMBER:
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_PAGE_NUMBER,
          payload: {
            name: key,
            value: parseInt(value)
          }
        });
        break;
      case SearchFiltersTypes.DELIVERY_DATE:
        if (isValidDate(value)) {
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: value
            }
          });
        }
        break;
      case SearchFiltersTypes.COMPOUNDS:
      case SearchFiltersTypes.AREAS:
      case SearchFiltersTypes.DEVELOPERS:
      case SearchFiltersTypes.PROPERTY_TYPES:
      case SearchFiltersTypes.AMENITIES:
      case SearchFiltersTypes.BEDROOMS:
      case SearchFiltersTypes.BATHROOMS:
      case SearchFiltersTypes.INSTALLMENT_YEARS:
        value &&
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
            payload: {
              name: key,
              value: convertStringArrayToNumberArray(
                value.toString().split(',')
              )
            }
          });
        break;
      case SearchFiltersTypes.COMPLETION_TYPE:
      case SearchFiltersTypes.SALE_TYPE:
        value &&
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
            payload: {
              name: key,
              value: value.toString().split(',')
            }
          });
        break;
      case SearchFiltersTypes.SORT_BY:
        value &&
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_SORTING_ORDER,
            payload: {
              order_by: value.split(',')[0],
              order_direction: value.split(',')[1]
            }
          });
        break;
      case SearchFiltersTypes.HAS_GARDEN:
      case SearchFiltersTypes.HAS_ROOF:
      case SearchFiltersTypes.NAWY_NOW:
        if (value === 'true' || value === 'false')
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: Boolean(value)
            }
          });
        break;
    }
  }
};

export const mapSearchFiltersKeysToSearchApiKeys = (
  searchFilterKey: SearchFiltersTypes
) => {
  switch (searchFilterKey) {
    case SearchFiltersTypes.CATEGORY:
      return 'show';
    case SearchFiltersTypes.COMPOUNDS:
      return 'compounds_ids';
    case SearchFiltersTypes.AREAS:
      return 'areas_ids';
    case SearchFiltersTypes.DEVELOPERS:
      return 'developers_ids';
    case SearchFiltersTypes.PROPERTY_TYPES:
      return 'property_type_id';
    case SearchFiltersTypes.AMENITIES:
      return 'compounds_amenities';
    case SearchFiltersTypes.BEDROOMS:
      return 'number_of_bedrooms';
    case SearchFiltersTypes.BATHROOMS:
      return 'number_of_bathrooms';
    case SearchFiltersTypes.COMPLETION_TYPE:
      return 'finishing';
    case SearchFiltersTypes.DELIVERY_DATE:
      return 'min_ready_by';
    case SearchFiltersTypes.DOWN_PAYMENT:
      return 'max_down_payment';
    case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
      return 'max_installments';
    case SearchFiltersTypes.SALE_TYPE:
      return 'resale';
    case SearchFiltersTypes.PAGE_NUMBER:
      return 'start';
    default:
      return searchFilterKey;
  }
};

export const mapSearchFiltersValuesToSearchApiValues = (
  searchFilterKey: SearchFiltersTypes,
  searchFilterValue: any
) => {
  switch (searchFilterKey) {
    case SearchFiltersTypes.BEDROOMS:
    case SearchFiltersTypes.BATHROOMS:
      return formatSearchValuesWithGreaterThan(searchFilterValue, 5);
    case SearchFiltersTypes.INSTALLMENT_YEARS:
      return formatSearchValuesWithGreaterThan(searchFilterValue, 6);
    case SearchFiltersTypes.DELIVERY_DATE:
      return searchFilterValue
        ? {
            date: searchFilterValue.split('-'),
            exclude_movenow: false
          }
        : undefined;
    case SearchFiltersTypes.PAGE_NUMBER:
      return (searchFilterValue - 1) * 12 + 1;

    default:
      return searchFilterValue;
  }
};

export const handleSelectOptionsWithMaxValue = (
  options: InputOption[],
  maxDisplayedValue: number | undefined,
  setNewOptions: any
) => {
  if (maxDisplayedValue) {
    setNewOptions((): InputOption[] => {
      const res = options.filter((curr: InputOption) => {
        const num = curr.value.toString().split('-')[0];
        if (parseInt(num) < maxDisplayedValue) {
          return curr;
        } else if (parseInt(num) == new Date().getFullYear()) {
          return curr;
        } else if (parseInt(num) == maxDisplayedValue) {
          const res = curr['options'].displayedValue + '+';
          const arr = Array.from(res);
          let duplicated = arr.filter(item => item != '+');
          duplicated.push('+');

          let thedisplayed = '';
          duplicated.map(curr => {
            thedisplayed += curr;
          });
          curr['options'].displayedValue = thedisplayed;
          return curr;
        }
      });
      return res;
    });
  } else {
    setNewOptions(options);
  }
};

export const formatSearchValuesWithGreaterThan = (
  arr: number[] | undefined,
  greaterThan: number
) => {
  if (!arr) return undefined;
  const greaterThanIndex = arr.findIndex(val => val === greaterThan);
  if (greaterThanIndex == -1)
    return {
      exact: arr
    };

  const newArr = arr.filter(value => {
    return value != greaterThan;
  });

  return {
    ...(newArr.length > 0 && { exact: [...newArr] }),
    greater_than: greaterThan
  };
};

export const resetFilters = (
  router: NextRouter,
  searchFiltersDispatch: Dispatch<SearchFiltersActions>,
  shallow?: boolean
) => {
  searchFiltersDispatch({
    type: SearchFiltersActionTypes.RESET
  });
  const { query, push } = router;
  delete query.device;
  Object.keys(query).forEach(queryParam => {
    if (router.pathname.includes(queryParam)) {
      delete query[queryParam];
    }
  });
  // to redirect to search page when you reset filters from buy SEO page
  let pathname = router.asPath;
  if (router.asPath.includes('/buy')) {
    pathname = '/search';
    return;
  }

  if (
    (router.asPath.includes('/compound') &&
      !router.asPath.includes('/property')) ||
    router.asPath.includes('/area') ||
    router.asPath.includes('/developer')
  ) {
    push(
      {
        query: {
          category: 'property'
        }
      },
      undefined,
      { shallow: shallow ?? true }
    );

    return;
  }

  if (!router.asPath.includes('/sahel-map')) {
    push(
      {
        query: {
          ...(router.query.compounds &&
            !router.asPath.includes('/nawy-now') && {
              compounds: router.query.compounds
            }),
          ...(router.query.developers && {
            developers: router.query.developers
          }),
          ...(router.query.areas &&
            !router.asPath.includes('/nawy-now') && {
              areas: router.query.areas
            })
        },
        pathname: pathname
          .split('?')[0]
          .replace('(/desktop|/mobile|/undefined)', '')
      },
      undefined,
      { shallow: shallow ?? true }
    );
  }
};

export const handleChangePage = (
  pageNumber: number,
  searchFiltersDispatch: Dispatch<SearchFiltersActions>,
  router?: NextRouter
) => {
  searchFiltersDispatch({
    type: SearchFiltersActionTypes.UPDATE_PAGE_NUMBER,
    payload: {
      name: SearchFiltersTypes.PAGE_NUMBER,
      value: pageNumber
    }
  });
  if (!router) return;
  if (pageNumber == 1)
    updateQuery(router, [], [SearchFiltersTypes.PAGE_NUMBER]);
  else
    updateQuery(
      router,
      [
        {
          name: SearchFiltersTypes.PAGE_NUMBER,
          value: pageNumber.toString()
        }
      ],
      undefined
    );
};

export const filterEventMap = (filterName: string) => {
  switch (filterName) {
    case 'bedrooms':
      return 'beds';
    case 'bathrooms':
      return 'baths';
    case 'finishing':
      return 'finish_type';
    case 'installment_years':
      return 'install_yrs';
    case 'down_payment':
      return 'max_dwn_pymnt';
    case 'monthly_installments':
      return 'max_monthly_install';
    default:
      return filterName;
  }
};

export const constructSsrSearchBody = (query: any) => {
  let body: any = { show: 'compound' };
  if (Object.keys(query).length == 0) return { show: 'compound' };
  for (const [key, value] of Object.entries(query) as any) {
    switch (key) {
      case SearchFiltersTypes.CATEGORY:
        if (value == 'property' || 'compound') {
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key as any)]: value
          };
          break;
        }
      case SearchFiltersTypes.BEDROOMS:
      case SearchFiltersTypes.BATHROOMS:
      case SearchFiltersTypes.INSTALLMENT_YEARS:
        let values;
        const result = value
          ?.toString()
          .split(',')
          .map((val: string) => parseInt(val));
        values = formatSearchValuesWithGreaterThan(result, 6);
        body = {
          ...body,
          [mapSearchFiltersKeysToSearchApiKeys(key as any)]: values
        };
        break;
      case SearchFiltersTypes.COMPOUNDS:
      case SearchFiltersTypes.AREAS:
      case SearchFiltersTypes.DEVELOPERS:
      case SearchFiltersTypes.PROPERTY_TYPES:
      case SearchFiltersTypes.AMENITIES:
        {
          let values = convertStringArrayToNumberArray(
            value.toString().split(',')
          );
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key as any)]: values
          };
        }
        break;
      case SearchFiltersTypes.MIN_PRICE:
      case SearchFiltersTypes.MAX_PRICE:
      case SearchFiltersTypes.MIN_UNIT_AREA:
      case SearchFiltersTypes.MAX_UNIT_AREA:
      case SearchFiltersTypes.DOWN_PAYMENT:
      case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
        if (!isNaN(value)) {
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key as any)]: parseInt(value)
          };
        }
        break;
      case SearchFiltersTypes.PAGE_NUMBER:
        if (!isNaN(value)) {
          const newValue = (parseInt(value) - 1) * 12 + 1;
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key as any)]: newValue
          };
        }
        break;

      case SearchFiltersTypes.DELIVERY_DATE:
        if (isValidDate(value))
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key as any)]: {
              date: value.split('-'),
              exclude_movenow: false
            }
          };
        break;
      case SearchFiltersTypes.COMPLETION_TYPE:
      case SearchFiltersTypes.SALE_TYPE:
        body = {
          ...body,
          [mapSearchFiltersKeysToSearchApiKeys(key as any)]: value
            .toString()
            .split(',')
        };
        break;
      case SearchFiltersTypes.SORT_BY:
        body = {
          ...body,
          [mapSearchFiltersKeysToSearchApiKeys(key as any)]: {
            order_by: value.split(',')[0],
            order_direction: value.split(',')[1]
          }
        };
        break;
      case SearchFiltersTypes.HAS_GARDEN:
      case SearchFiltersTypes.HAS_ROOF:
      case SearchFiltersTypes.NAWY_NOW:
        if (value === 'true' || value === 'false')
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key as any)]: Boolean(value)
          };
        break;
    }
  }
  return body;
};
